<template>
  <div class="container">
     <!-- <search-item @input="searchlist"></search-item> -->
    <div class="flex fwtj">
      <div class="item" v-for="(item, i) in list" :key="i" >
        <div class="img"  @click="openpage('/fwdetail',item.id)">
        <img :src="item.thumb_images[0]" alt="" v-if="item.thumb_images" />
        </div>
        <div class="btn2">
          <div class="name"  @click="openpage('/fwdetail',item.id)">{{ item.title }}</div>
          <div class="intro"  @click="openpage('/fwdetail',item.id)">{{ item.description }}</div>
          <div class="flex zixun">
            <div class="btn btn-warning-sm" @click="getkefu()">咨询管家</div>
            <div class="btn btn-primary-sm"  @click="openpage('/fbxq',item.id,true)">发布需求</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" v-show="total>0">
        <el-pagination
       background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="999"
        layout="total, prev, pager, next"
        :total="total">
    </el-pagination>
      </div>
       <el-empty :image-size="150" v-show="total==0"></el-empty>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import searchItem from "@c/common/searchitem.vue";
export default {
  components: {},
  data() {
    return {
      list: [],
      currentPage:1,
       total:0,
        keyword:""
    };
  },
   computed: {
    ...mapState(["isLogin", "userInfo"]),
   },
   mounted(){
     
      this.handleCurrentChange(this.currentPage)
  },
  methods: {
     ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
     getkefu() {
        if (this.isLogin != 1) {
           this.setLoginDialog(true);
      
        }else{
          this.$store.dispatch("setkefu");
        }
    
    },
     searchlist(val){
      this.keyword=val;
      this.handleCurrentChange(this.currentPage)
    },
     handleCurrentChange(val) {
      //  this.currentPage=val;
        this.$http.fwtjlist({
          offset:(val-1)*999,
          limit:999, 
          keyword:this.keyword
        }).then(res=>{
          this.list=res.data.list;
         this.total=res.data.total;
        })
      },
     openpage(path,id, islogin){
         if (islogin) {
        if (this.isLogin != 1) {
           this.setLoginDialog(true);
          // this.$message.error("请先登录");
          return;
        }
      }
        this.$router.push({
            path,
            query: {
              id,
            },
        })
     }
  } 
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  padding: 0 20px;
  margin-bottom: 20px;
}
.pagination{
  // background: #fff;
  text-align: center;
  padding: 20px ;
}
.fwtj {
  width: 100%;
  flex-wrap: wrap;
//   justify-content: space-evenly;
  .item {
    width: calc(25% - 15px);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:nth-child(4n+4){
        margin-right: 0;
    }
    .img img{
      width: 100%;
      height: 200px;
      object-fit: cover;
       display: block;
    }
  }

  .btn2 {
    padding:10px 20px ;
    font-size: 20px;
    margin-bottom: 10px;
    .name {
        font-size: 18px;
        margin-bottom: 6px;
          @include single-line-ellipsis;
    }
    .intro{
        font-size: 14px;
        color: #666;
        @include  multi-line-ellipsis(2)
    }
    .zixun {
        margin-top: 10px;
      justify-content: space-between;
    }
  }
}
</style>
